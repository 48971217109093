import React, {Component} from 'react';
import './Home.sass';

class Home extends Component {
    componentDidMount() {
        document.title = "NeosVR WebApp";
    }

    render() {
        return (
            <div className="Home">
                <p>Comming soon</p>
            </div>
        );
    }
}

export default Home;
