import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {loadInventoryOwnerDirectory, resetInventoryDirectory} from "../../../services/user/actions";
import InventoryList from "../components/InventoryList";

class InventoryOwnerDirectoryList extends Component {

    componentDidMount() {
        document.title = "Inventory - NeosVR WebApp";

        const directoryId = decodeURIComponent(this.props.match.params.directoryId);
        const owner = this.props.match.params.owner;

        this.props.loadInventoryOwnerDirectory(this.props.userSession, directoryId, owner);
    }

    componentWillUnmount() {
        this.props.resetInventoryDirectory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.directoryId !== this.props.match.params.directoryId) {
            const directoryId = decodeURIComponent(this.props.match.params.directoryId);
            const owner = this.props.match.params.owner;

            this.props.resetInventoryDirectory();
            this.props.loadInventoryOwnerDirectory(this.props.userSession, directoryId, owner);
        }
    }

    render() {
        const {logged, inventoryDirectory, inventoryDirectoryActualPath} = this.props;

        if (!logged) {
            return <Redirect to='/'/>;
        }

        return (
            <div className="Home">
                <h2>Inventory: {inventoryDirectoryActualPath}</h2>

                <InventoryList inventory={inventoryDirectory} inventoryDirectoryActualPath={inventoryDirectoryActualPath}/>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
        inventoryDirectory: state.user.inventoryDirectory,
        inventoryDirectoryActualPath: state.user.inventoryDirectoryActualPath
    }
};

export default connect(mapStateToProps, {loadInventoryOwnerDirectory, resetInventoryDirectory})(InventoryOwnerDirectoryList);
