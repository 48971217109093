import React, {Component} from 'react';
import InventoryItem from "../components/InventoryItem";
import InventoryLink from "./InventoryLink";
import InventoryOwnerDirectory from "./InventoryOwnerDirectory";

class InventoryList extends Component {
    render() {
        const {inventory, inventoryDirectoryActualPath} = this.props;

        if (!inventory) {
            return <div>Loading...</div>;
        }

        const inventoryDirectories = inventory.filter(item => item.recordType === "directory");
        const inventoryLinks = inventory.filter(item => item.recordType === "link");
        const inventoryObjects = inventory.filter(item => item.recordType === "object");

        return (
            <div className="InventoryList">
                {inventoryDirectories.length > 0 && [
                    <h3 key={1} className="my-5">Directories</h3>,
                    <div key={2} className="Inventory row">
                        {inventoryDirectories.map((inventoryItem, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 text-center mb-5">
                                <InventoryOwnerDirectory data={inventoryItem} inventoryDirectoryActualPath={inventoryDirectoryActualPath} />
                            </div>
                        ))}
                    </div>
                ]}

                {inventoryLinks.length > 0 && [
                    <h3 key={1} className="my-5">Links</h3>,
                    <div key={2} className="Inventory row">
                        {inventoryLinks.map((inventoryItem, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 text-center mb-5">
                                <InventoryLink data={inventoryItem} />
                            </div>
                        ))}
                    </div>
                ]}

                {inventoryObjects.length > 0 && [
                    <h3 key={1} className="my-5">Objects</h3>,
                    <div key={2} className="Inventory row">
                        {inventoryObjects.map((inventoryItem, index) => (
                            <div key={index} className="col-12 col-sm-6 col-md-4 text-center mb-5">
                                <InventoryItem data={inventoryItem} />
                            </div>
                        ))}
                    </div>
                ]}
            </div>
        );
    }
}

export default InventoryList;
