import React, {Component} from 'react';
import {BrowserRouter as Router, Link, Route, Switch, NavLink} from "react-router-dom";
import Home from './components/Home/Home';
import NotFound from './components/NotFound/NotFound';
import User from './components/User/User';
import ReactModal from 'react-modal';
import './App.sass';
//import ReactGA from 'react-ga';
import API from "./api";
import {connect} from "react-redux";
import Loader from "./components/Loader/Loader";
import OAuth2 from "./components/OAuth2/OAuth2";
import {refreshUser, logout} from './services/user/actions';
import Profile from "./components/Profile/Profile";
import Messages from "./components/Messages/Messages";
import Inventory from "./components/Inventory/Inventory";
import InventoryLinkList from "./components/Inventory/layouts/InventoryLinkList";
import InventoryOwnerDirectoryList from "./components/Inventory/layouts/InventoryOwnerDirectoryList";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
        this.handleCloseError = this.handleCloseError.bind(this);

        if (props.user) {
            API.jwt = props.user.jwt;
        }
    }

    handleCloseError() {
        this.setState({hasError: false});
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log("chyba");
    }

    componentDidMount() {
        //ReactGA.initialize('UA-XXX-XXX');
        const {userSession} = this.props;
        if (userSession) {
            if (userSession.expire) {
                let expire = new Date(userSession.expire);
                let now = new Date();
                if (now >= expire) {
                    this.props.logout(userSession);
                } else {
                    this.props.refreshUser(userSession);
                }
            }
        }

        this.sendPageView();
    }

    sendPageView() {
        //ReactGA.set({page: window.location.pathname});
        //ReactGA.pageview(window.location.pathname);
    }

    render() {
        const {logged} = this.props;

        return (
            <Router>
                <div className="App">
                    <header className="App-header">
                        <div className="container">
                            <nav className="App-nav">
                                <ul>
                                    <li><NavLink exact={true} activeClassName='is-active' to="/">Home</NavLink></li>
                                    {logged && [
                                        <li key={1}><NavLink activeClassName='is-active' to="/inventory">Inventory</NavLink></li>,
                                        <li key={2}><NavLink activeClassName='is-active' to="/messages">Messages</NavLink></li>,
                                        <li key={3}><NavLink activeClassName='is-active' to="/profile">Profile</NavLink></li>
                                    ]}
                                    <li className="pl-sm-4"><User/></li>
                                </ul>
                            </nav>
                            <h1><Link to="/"><img src="/assets/images/logo.png" width="98" height="28"
                                                  alt="NeosVR"/></Link></h1>
                        </div>
                    </header>

                    <main>
                        <div className="container">
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/profile" component={Profile}/>
                                <Route exact path="/inventory" component={Inventory}/>
                                <Route exact path="/inventory/directory/:owner/:directoryId" component={InventoryOwnerDirectoryList} />
                                <Route exact path="/inventory/link/:owner/:linkId" component={InventoryLinkList} />
                                <Route exact path="/messages" component={Messages}/>
                                <Route exact path="/oauth2/*" component={OAuth2}/>
                                <Route path="*" component={NotFound} status={404}/>
                            </Switch>
                        </div>
                    </main>

                    <div className="container">
                        <footer>
                            <p>
                                &copy; 2019
                            </p>
                        </footer>
                    </div>

                    <ReactModal
                        isOpen={this.state.hasError}
                        onRequestClose={this.handleCloseError}
                        ariaHideApp={false}
                        style={{content: {background: "none", border: "none"}, overlay: {background: "rgba(0,0,0,.6)"}}}
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Problem</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={this.handleCloseError}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-danger">
                                        Ups ... An error has occurred, sorry.<br/>
                                        Please try again later.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ReactModal>

                    <Loader/>
                </div>
            </Router>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
    }
};

export default connect(mapStateToProps, {
    refreshUser, logout
})(App);