import React, {Component} from 'react';
import MessageItem from "./MessageItem";
import {connect} from "react-redux";
import {loadMessages, sendMessage} from "../../../services/user/actions";

class MessagesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        };

        this.onClickSend = this.onClickSend.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
    }

    onClickSend() {
        if (this.state.content.trim() === "") {
            return false;
        }
        this.props.sendMessage(this.props.userSession, this.props.choosedFriend, this.state.content.trim());
    }

    onChangeText(e) {
        this.setState({content: e.target.value});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.choosedFriend !== this.props.choosedFriend) {
            this.props.loadMessages(this.props.userSession, this.props.choosedFriend);
        }
    }

    render() {
        const {messages, friends, choosedFriend, user, sending} = this.props;

        let friend = null;
        friends.map((f) => {
            if (f.id === choosedFriend) {
                friend = f;
            }
        });

        return (
            <div className="Messages-MessagesList">
                <div className={"Messages-MessagesList__form " + (sending ? "Messages-MessagesList__form--sending" : "")}>
                    <textarea className="Messages-MessagesList__textarea" placeholder="Your text" onChange={this.onChangeText}></textarea>
                    <button className="button Messages-MessagesList__button" onClick={this.onClickSend}>Send</button>
                </div>
                {messages.map((message, index) => (
                    <MessageItem message={message} friend={friend} me={user}/>
                ))}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.user.user,
        userSession: state.user.userSession,
        choosedFriend: state.user.choosedFriend,
        messages: state.user.messages,
        friends: state.user.friends,
        sending: state.user.sending
    }
};

export default connect(mapStateToProps, {loadMessages, sendMessage})(MessagesList);