import React, {Component} from 'react';
import './Messages.sass';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {loadFriends} from "../../services/user/actions";
import FriendsList from "./components/FriendsList";
import MessagesList from "./components/MessagesList";

class Messages extends Component {
    componentDidMount() {
        document.title = "Messages - NeosVR WebApp";

        this.props.loadFriends(this.props.userSession);
    }

    render() {
        const {logged} = this.props;

        if (!logged) {
            return <Redirect to='/'/>;
        }

        return (
            <div className="Home">
                <h2>Messages</h2>

                <div className="Messages">
                    <div className="row">
                        <div className="col-md-3">
                            <FriendsList friends={this.props.friends}/>
                        </div>
                        <div className="col-md-9">
                            <MessagesList/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
        friends: state.user.friends,
        choosedFriend: state.user.choosedFriend
    }
};

export default connect(mapStateToProps, {loadFriends})(Messages);
