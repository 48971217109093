import React, {Component} from 'react';
import './Profile.sass';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import BytesChart from "./components/BytesChart";

class Profile extends Component {
    componentDidMount() {
        document.title = "Profile - NeosVR WebApp";
    }

    render() {
        const {logged, user} = this.props;

        if (!logged) {
            return <Redirect to='/'/>;
        }

        let registrationDate = new Date(user.registrationDate);
        registrationDate = (registrationDate.getUTCMonth() + 1) + "/" + registrationDate.getUTCDate() + "/" + registrationDate.getUTCFullYear();

        return (
            <div className="Home">
                <h2>Profile</h2>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <table className="table-data">
                            <tbody>
                            <tr>
                                <th>ID:</th>
                                <td>{user.id}</td>
                            </tr>
                            <tr>
                                <th>Username:</th>
                                <td>{user.username}</td>
                            </tr>
                            <tr>
                                <th>Registration:</th>
                                <td>{registrationDate}</td>
                            </tr>
                            <tr>
                                <th>Avatar:</th>
                                <td><img src={user.avatar} width="128" height="128" alt={user.username}/></td>
                            </tr>
                            <tr>
                                <th>Patreon Supporter:</th>
                                <td>{user.isPatreonSupporter ? "yes" : "no"}</td>
                            </tr>
                            <tr>
                                <th>NCR Deposit Address:</th>
                                <td>{user.NCRdepositAddress ? user.NCRdepositAddress : "-"}</td>
                            </tr>
                            {user.credits && [
                                <tr key={"ncr"}>
                                    <th>NCR Credits:</th>
                                    <td>{user.credits.NCR}</td>
                                </tr>,
                                <tr key={"kfc"}>
                                    <th>KFC Credits:</th>
                                    <td>{user.credits.KFC}</td>
                                </tr>
                            ]}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 col-sm-6">
                        <table className="table-data">
                            <tbody>
                            <tr>
                                <th>Space:</th>
                                <td><BytesChart user={user}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
    }
};

export default connect(mapStateToProps, null)(Profile);
