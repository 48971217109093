import React, {Component} from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {loadInventoryLink, resetInventoryDirectory} from "../../../services/user/actions";
import InventoryList from "../components/InventoryList";

class InventoryLinkList extends Component {

    componentDidMount() {
        document.title = "Inventory - NeosVR WebApp";

        const linkId = this.props.match.params.linkId;
        const owner = this.props.match.params.owner;

        this.props.loadInventoryLink(this.props.userSession, linkId, owner);
    }

    componentWillUnmount() {
        this.props.resetInventoryDirectory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.linkId !== this.props.match.params.linkId) {
            const linkId = this.props.match.params.linkId;
            const owner = this.props.match.params.owner;

            this.props.resetInventoryDirectory();
            this.props.loadInventoryLink(this.props.userSession, linkId, owner);
        }
    }

    render() {
        const {logged, inventoryDirectory, inventoryDirectoryActualPath} = this.props;

        if (!logged) {
            return <Redirect to='/'/>;
        }

        return (
            <div className="Home">
                <h2>Inventory: {inventoryDirectoryActualPath}</h2>

                <InventoryList inventory={inventoryDirectory} inventoryDirectoryActualPath={inventoryDirectoryActualPath}/>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession,
        inventoryDirectory: state.user.inventoryDirectory,
        inventoryDirectoryActualPath: state.user.inventoryDirectoryActualPath
    }
};

export default connect(mapStateToProps, {loadInventoryLink, resetInventoryDirectory})(InventoryLinkList);
