import React, {Component} from 'react';
import './OAuth2.sass';
import {connect} from "react-redux";
import queryString from 'query-string';

class OAuth2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: queryString.parse(this.props.location.search)
        };
    }

    render() {
        const {user, logged} = this.props;

        if (!("redirect_uri" in this.state.params)) {
            return (
                <div>
                    Redirect URI not defined.
                </div>
            );
        }

        if (logged) {
            let redirect_uri = "/api/oauth2/token.php"
                + "?&user=" + this.props.userSession.userId
                + "&token=" + this.props.userSession.token
                + "&redirect_uri=" + this.state.params.redirect_uri;

            return (
                <div>
                    <div className="OAuth2__continue">
                        <img src={user.avatar} alt={user.username}/>
                        <p className="OAuth2__continue__name">{user.username}</p>

                        <a href={redirect_uri} className="OAuth2__continue__button button">Continue
                        </a>

                        <p><a href="/" onClick={() => window.close()}>Cancel</a></p>
                    </div>
                </div>
            );
        }

        return (
            <div>
                Please, login to authorize
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        logged: state.user.logged,
        user: state.user.user,
        userSession: state.user.userSession
    }
};

export default connect(mapStateToProps, null)(OAuth2);
